<template>
  <div>
    <label class="v-label v-label--active v-label--is-disabled theme--light">{{ label }}</label>
    <v-card>
      <v-tabs hide-slider right color="primary">
        <v-tab> <v-icon left>mdi-file-edit-outline</v-icon> Editor </v-tab>
        <v-tab> <v-icon left>mdi-code-block-tags</v-icon> Source </v-tab>
        <v-tab-item>
          <v-container>
            <tiptap-vuetify
              v-bind="$attrs"
              v-on="$listeners"
              :value="value"
              @input="onInput"
              :extensions="extensions"
              ref="editor"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-textarea
              hide-details
              solo
              class="pa-0 ma-0 textarea-code"
              :value="value"
              @input="onInput"
              v-bind="$attrs"
              v-on="$listeners"
              rows="7"
            ></v-textarea>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<style>
.textarea-code {
  font-family: "Courier New", Courier, monospace;
  font-size: smaller;
}
</style>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline
} from "tiptap-vuetify";

export default {
  name: "Editor",
  components: { TiptapVuetify },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ]
    };
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  }
};
</script>
