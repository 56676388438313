<template>
  <v-card :loading="isBusy">
    <v-toolbar>
      <v-toolbar-title>Content - {{ form.contentKey }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="
              onSubmit();
              isEditing = false;
            "
            v-bind="attrs"
            :disabled="!valid"
            v-on="on"
            :loading="isBusy"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!isEditing && tab != 3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="isEditing = true"
            v-bind="attrs"
            :disabled="!valid"
            v-on="on"
            :loading="isBusy"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="
              fetchData();
              isEditing = false;
            "
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Cancel") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small v-bind="attrs" v-on="on" @click="onDelete" :disabled="isEditing">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Delete") }}</span>
      </v-tooltip>
    </v-toolbar>
    <ApiError :errors="errors"></ApiError>

    <v-row>
      <v-col>
        <v-form v-model="valid">
          <v-card-text>
            <v-text-field
              label="Content Key"
              v-model="form.contentKey"
              :disabled="!isEditing"
              :rules="rules.contentKey"
              class="required"
            />
            <v-text-field label="Description" v-model="form.description" :disabled="!isEditing" />
            <ContentEditor
              label="Body"
              class="required"
              v-model="form.body"
              :disabled="!isEditing"
              :rules="rules.body"
            />
          </v-card-text>
        </v-form>
      </v-col>
      <v-divider vertical class="mx-4"></v-divider>
      <v-col>
        <h2>Preview</h2>
        <v-divider horizontal></v-divider>
        <div v-html="form.body"></div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import ValidationService from "@/gapp-components/services/validation.service.js";
import { mapGetters } from "vuex";
import ContentEditor from "./ContentEditor.vue";

export default {
  name: "Content",
  components: {
    ApiError,
    ContentEditor
  },
  metaInfo: {
    title: "Content"
  },
  data() {
    return {
      form: {
        contentKey: "",
        body: "",
        description: ""
      },
      isNew: true,
      isEditing: false,
      valid: true,
      isBusy: false,
      rules: {
        contentKey: ValidationService.generateTextValidationRules("Content key"),
        body: [v => !!v || "Body is required"]
      },
      errors: {},
      page: 1,
      tab: 0
    };
  },
  mounted() {
    this.isNew = this.$route.params.id == 0 ? true : false;

    if (!this.isNew) {
      this.fetchData();
    } else {
      this.isEditing = true;
    }
    this.breadcrumb();
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedClient", "selectedProgram"])
  },
  methods: {
    async onSubmit() {
      this.isBusy = true;
      let postForm = Object.assign({}, this.form);
      this.errors = {};

      try {
        if (this.isNew) {
          postForm.program = this.$api.getSelfUrl("programs", this.selectedProgram);
          let { data } = await this.$api.post("/api/contents", postForm);
          this.$router.push({ name: "content", params: { id: data.id } });
        } else {
          let patchForm = {
            contentKey: postForm.contentKey,
            description: postForm.description,
            body: postForm.body
          };

          let { data } = await this.$api.patch("/api/contents/" + this.$route.params.id, patchForm);
          this.form = data;
        }
        this.isBusy = false;
      } catch (error) {
        let apiError = {};
        if (error.response && error.response.status === 409) {
          apiError.message = `A content with the key '${this.form.contentKey}' already exists.`;
          apiError.status = error.response.status;
        } else {
          apiError = this.$api.getErrorsFromResponse(error);
        }

        this.errors = apiError;
        this.isBusy = false;
      }
    },

    fetchData() {
      this.isBusy = true;
      this.$api
        .get("/api/contents/" + this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
        })
        .then(() => {
          this.breadcrumb();
          this.isBusy = false;
        });
    },
    onDelete() {
      this.isBusy = true;
      this.$api
        .delete("/api/contents/" + this.$route.params.id)
        .then(() => {
          this.$router.push({ name: "contents" });
        })
        .catch(error => {
          this.isBusy = false;
          this.isEditing = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    breadcrumb() {
      const breadcrumbBase = [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        {
          text: this.$i18n.translate("Clients"),
          to: { name: "clients" },
          exact: true
        },
        {
          text: this.selectedClient.name,
          to: {
            name: "client",
            params: { id: this.selectedClient.id },
            exact: true
          }
        },
        {
          text: this.$i18n.translate("Programs"),
          to: { name: "programs" },
          exact: true
        },
        {
          text: this.selectedProgram.name,
          to: {
            name: "program",
            params: { id: this.selectedProgram.id },
            exact: true
          }
        },
        {
          text: this.$i18n.translate("Contents"),
          to: { name: "contents" },
          exact: true
        }
      ];
      const finalBreadcrumb = this.isNew ? { text: "New Content" } : { text: this.form.contentKey };

      this.$store.dispatch("setBreadcrumb", [...breadcrumbBase, finalBreadcrumb]);
    }
  }
};
</script>
